$icomoon-font-path: "../assets/fonts" !default;

$icomoon_ic_search: \e90c;
$icomoon_ic_arrow_drop_down: \e900;
$icomoon_ic_arrow_drop_up: \e901;
$icomoon_ic_checkbox: \e902;
$icomoon_ic_chevron_left: \e903;
$icomoon_ic_chevron_right: \e904;
$icomoon_ic_columns: \e905;
$icomoon_ic_expand_less: \e906;
$icomoon_ic_expand_more: \e907;
$icomoon_ic_fullscreen: \e908;
$icomoon_ic_settings: \e909;
$icomoon_ic_statistics: \e90a;
$icomoon_ic_user: \e90b;
$icomoon_ic_calendar: \e90b;
$icomoon_ic_cross: \u2715;
$icomoon_ic_sync: \1f327;

@font-face {
  font-family: 'icomoon';
  src: url('#{$icomoon-font-path}/icomoon.ttf?w0yxhc') format('truetype'),
  url('#{$icomoon-font-path}/icomoon.woff?w0yxhc') format('woff'),
  url('#{$icomoon-font-path}/icomoon.svg?w0yxhc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icomoon_"], [class*=" icomoon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

.icomoon_ic_search {
  &:before {
    content: unicode($icomoon_ic_search);
  }
}

.icomoon_ic_arrow_drop_down {
  &:before {
    content: unicode($icomoon_ic_arrow_drop_down);
  }
}

.icomoon_ic_arrow_drop_up {
  &:before {
    content: unicode($icomoon_ic_arrow_drop_up);
  }
}

.icomoon_ic_checkbox {
  &:before {
    content: unicode($icomoon_ic_checkbox);
  }
}

.icomoon_ic_chevron_left {
  &:before {
    content: unicode($icomoon_ic_chevron_left);
  }
}

.icomoon_ic_chevron_right {
  &:before {
    content: '';
  }
}

.icomoon_ic_columns {
  &:before {
    content: unicode($icomoon_ic_columns);
  }
}

.icomoon_ic_expand_less {
  &:before {
    content: unicode($icomoon_ic_expand_less);
  }
}

.icomoon_ic_expand_more {
  &:before {
    content: unicode($icomoon_ic_expand_more);
  }
}

.icomoon_ic_fullscreen {
  &:before {
    content: unicode($icomoon_ic_fullscreen);
  }
}

.icomoon_ic_settings {
  &:before {
    content: unicode($icomoon_ic_settings);
  }
}

.icomoon_ic_statistics {
  &:before {
    content: unicode($icomoon_ic_statistics);
  }
}

.icomoon_ic_user {
  &:before {
    content: unicode($icomoon_ic_user);
  }
}

.icomoon_ic_cross {
  &:before {
    content: "&#x2715";
  }
}

.icomoon_ic_sync {
    &:before {
        content: unicode($icomoon_ic_sync);
    }
}
