$colorPrimary: #0070D2;
$colorSecondary: #4BCA81;
$colorDanger: #D20000;
$color1: #16325C;
$color2: #54698D;
$color3: #A8B7C7;
$color4: #E0E5EE;


$W1650: 1650.98px;
$W1590: 1590.98px;
$W1540: 1540.98px;
$W1500: 1500.98px;
$W1470: 1470.98px;
$W1400: 1400.98px;
$W1375: 1375.98px;
$W1230: 1230.98px;
$W1200: 1200.98px;
$W1170: 1170.98px;
$W1100: 1100.98px;

$H650: 650px;

@mixin mediaDesktop($width) {
    @media only screen and (max-width: #{$width}) {
      @content;
    }
  }
  @mixin mediaDesktopHeight($height) {
    @media only screen and (max-height: #{$height}) {
      @content;
    }
  }