$grid-gutter-width: 10px !default;
@import 'variables';
@import 'mixins';
@import 'fonts';
@import 'radio';
@import 'helpers.scss';
@import 'node_modules/ng-zorro-antd/ng-zorro-antd.min.css';
* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  font-family: 'Roboto', 'Arial', 'Helvetica', sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: $color1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1024px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #c23934 !important;
}

table {
  border-collapse: separate;
}

#toast-container > div {
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.tabs-filter-list {
  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 50%;
    text-align: center;
    justify-content: center;
  }

  .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    width: 100%;
  }
}

table {
  tr {
    th.ant-table-cell {
      padding-left: 20px !important;
    }
    td.ant-table-cell {
      padding-left: 20px !important;
    }
  }
}

.ant-tooltip-inner {
  white-space: pre-line !important;
}

.ant-switch-checked {
  background: $colorPrimary;
}

.ant-select-item {
  font-weight: 400 !important;
}

.ant-select-item-option {
  font-weight: 400 !important;
}

.ant-select-item-option.inactive {
  font-weight: 400 !important;
  color: red !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled).inactive {
  font-weight: 600 !important;
  color: red !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled).inactive {
  font-weight: 400 !important;
  color: red;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 600 !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  font-weight: 400 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

label {
  color: rgba(0, 0, 0, 0.85);
}

.margin-group,
.connection-type-group {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  .ant-radio-group {
    width: 100%;
    label {
      width: 33.3%;
      text-align: center;
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}

.connection-type-group {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  .ant-radio-group {
    width: 100%;
    label {
      width: 25%;
      text-align: center;
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}

.required-label {
  color: #d20000;
}

.fake-label {
  color: rgba(0, 0, 0, 0.85);
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.ant-card-head {
  border-bottom: none;
  padding: 0;
}

.ant-card-body {
  padding: 0;
}

.ant-card-head-title {
  font-size: 30px;
  color: #16325c;
  font-weight: 400;
  line-height: 35px;
  padding-top: 0;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #0070d2;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #0070d2;
  &:hover {
    background-color: #0070d2;
  }
}

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  cursor: default;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  display: flex !important;
  align-items: center !important;
}

.ant-checkbox-inner {
  display: inline-block;
}

.ant-input:hover {
  border-color: #0070d2;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #0070d2;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  border-color: #0070d2 !important;
  background: #0070d2 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #0070d2;
}

.ant-checkbox {
  height: 16px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0070d2 !important;
}

.ant-checkbox-checked {
  height: 16px !important;
  border-color: #0070d2 !important;
  background: #0070d2 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  height: 16px !important;
  border-color: #0070d2 !important;
  background: #0070d2 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #0070d2;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #0070d2;
  &:hover {
    background-color: #0070d2;
  }
}

input[type='checkbox'] {
  height: 16px !important;
}

.ant-checkbox-inner {
  height: 16px !important;
}

.ant-card-bordered {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
}

.ant-modal-header {
  padding: 0px;
}

.ant-modal-wrap {
  overflow: hidden !important;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex: 1 1 100%;
  width: 100%;
  align-self: normal;
}

.ant-input-number-input {
  padding: 0;
}

.ant-input-suffix {
  color: #a8b7c7;
  cursor: default;
}

.ant-table-expanded-row-fixed {
  position: relative !important;
  padding: 16px 0 !important;
}

.ng-select .ng-select-container {
  min-height: 32px !important;
  border: 1px solid #a8b7c7;
}

.ant-table-pagination.ant-pagination {
  margin: 10px 20px 0 0 !important;
}

.ant-pagination-item-active a,
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #0070d2 !important;
}

.ant-picker-range-separator {
  padding: 0 8px 0 0;
  margin-left: -0.5px;
}

.ant-select-multiple .ant-select-selection-search {
  width: 80% !important;
}

.nz-disable-td .ant-table-cell {
  &.td-expand {
    padding: 0 !important;
  }
}

.ant-table.ant-table-middle .ant-table-tbody > tr > td {
  &.td-expand {
    padding: 0 !important;
  }
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  &.td-expand {
    padding: 0 !important;
  }
}

.ant-table {
  margin: 0 !important;
}

.ant-btn-primary {
  background-color: $colorPrimary;
  border-color: $colorPrimary;
  font-size: 14px !important;
}

.ant-modal-footer {
  text-align: left;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 7.5px !important;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #999;
}
.mat-slide-toggle-thumb {
  cursor: pointer;
}

.clearfix {
  @include clearfix;
}

.btn {
  @include button;
}

.scan-box {
  @include scan-box;
}
.btn--small {
  padding: 5px 0 !important;
  height: 25px !important;
  min-width: 55px !important;
}
.header {
  height: 50px;
  background: #fff;
  border-bottom: 2px solid rgba(22, 50, 92, 0.1);
  padding: 0 10px;
}

.header__logo {
  float: left;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  img {
    max-width: 156px;
    max-height: 35px;
    width: 100%;
    vertical-align: top;
  }
}

.header__nav {
  float: left;
  margin-left: 20px;
  li {
    display: inline-block;
    margin: 0 5px;
    position: relative;
    .arrow {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      right: 6px;
    }
    a {
      display: block;
      text-decoration: none;
      padding-top: 17px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      transition: color 0.2s;
      color: $color2;
      min-width: 50px;
      width: auto;
      position: relative;
      padding-bottom: 17px;
      &.stat-head-link {
        padding-right: 30px;
        cursor: pointer;
      }
    }
    &:hover > a,
    &:active > a,
    &.active > a {
      color: $color1;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        display: block;
        width: 100%;
        left: 0;
        background: $colorPrimary;
      }
    }
    &.active > a {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        display: block;
        width: 100%;
        left: 0;
        background: $colorPrimary;
      }
    }
    &:hover {
      .dropdown {
        opacity: 1;
        visibility: visible;
      }
      .arrow {
        path {
          fill: #0070d2;
        }
      }
    }
    &.active {
      .arrow {
        path {
          fill: #0070d2;
        }
      }
    }
  }
  .dropdown {
    width: 146px;
    position: absolute;
    top: 60px;
    left: -10px;
    background: #ffffff;
    border: 1px solid #d8dde6;
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(84, 105, 141, 0.5);
    border-radius: 4px;
    z-index: 9999999;
    opacity: 0;
    visibility: hidden;
    transition: 400ms;
    a {
      padding: 8px 5px 8px 15px;
      font-size: 12px;
      line-height: 19px;
      color: #000;
      text-align: left;
      font-weight: normal;
      &:hover {
        color: #0070d2;
        &:after {
          display: none;
        }
      }
      &.active {
        color: #0070d2;
        &:after {
          display: none;
        }
      }
    }
  }
}

.sections {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  ._td {
    width: 50%;
    position: relative;
    overflow: hidden;
    height: 100%;
    float: left;
    transition: width 0.2s;
    &:first-child {
      border-right: 1px solid #d8dde6;
    }
    &:last-child {
      border-left: 1px solid #d8dde6;
    }
  }
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

h2 {
  color: $colorPrimary;
  font-size: 36px;
  line-height: 1.16;
  font-weight: 400;
}

.form-group {
  position: relative;
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  outline: none;
  color: $color1;
  padding-left: 12px;
  height: 32px;
  &:focus {
    border: 1px solid #0076de;
    box-shadow: 0 0 3px 0 rgba(0, 118, 222, 0.5);
  }
  &.ng-touched.ng-invalid {
    border: 1px solid #c23934;
  }
  &::placeholder {
    color: #16325c;
    font-size: 13px;
  }
  &::placeholder {
    color: $color3;
    font-family: 'Roboto', 'Arial', 'Helvetica', sans-serif;
  }
  &:disabled {
    background: #f5f5f5;
  }
}

.form-group_inline {
  display: inline-block;
  vertical-align: middle;
}

.section__top {
  padding: 20px;
  h2 {
    margin-bottom: 15px;
  }
  .form-group_inline {
    max-width: 200px;
  }
  .form-group_inline {
    margin-left: 10px;
    .form-control {
      padding-left: 0px;
    }
    i {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      color: $color3;
      display: block;
      width: 36px;
      height: 32px;
      text-align: center;
      font-size: 16px;
      line-height: 32px;
    }
  }
}

.section__top {
  position: relative;
}

.columns-options-dropdown {
  position: absolute;
  top: 20px;
  right: 10px;
  .dropdown-component__body {
    top: 100%;
    right: 0;
  }
  .dropdown-component__content {
    top: 100%;
    left: 0;
  }
  .dropdown-component__incoming {
    top: 100%;
    right: 0;
  }
  .dropdown-component__outgoing {
    top: 100%;
    right: 0;
  }
}

.dropdown-component__body {
  position: absolute;
  width: 171px;
  border: 1px solid #d8dde6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(84, 105, 141, 0.5);
  padding: 0;
  overflow: auto;
  height: 0;
  margin-top: 2px;
  transition: all 0.2s;
  opacity: 0;
  z-index: 1000;
}

.dropdown-component__content {
  @extend .dropdown-component__body;
}
.dropdown-component__incoming {
  position: absolute;
  width: 171px;
  border: 1px solid #d8dde6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(84, 105, 141, 0.5);
  padding: 0;
  overflow: auto;
  height: 0;
  margin-top: 2px;
  transition: all 0.2s;
  opacity: 0;
  z-index: 1000;
}
.dropdown-component__outgoing {
  position: absolute;
  width: 171px;
  border: 1px solid #d8dde6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(84, 105, 141, 0.5);
  padding: 0;
  overflow: auto;
  height: 0;
  margin-top: 2px;
  transition: all 0.2s;
  opacity: 0;
  z-index: 1000;
}
.dropdown-component {
  position: relative;
}
.dropdown-component.opened {
  .dropdown-component__body {
    height: 200px;
    padding: 10px;
    opacity: 1;
  }
  .dropdown-component__content {
    @extend .dropdown-component__body;
  }
  .dropdown-component__incoming {
    height: 200px;
    padding: 10px;
    opacity: 1;
  }
  .dropdown-component__outgoing {
    height: 200px;
    padding: 10px;
    opacity: 1;
  }
}
.dp-drop {
  .dropdown-component__body {
    right: 0;
  }
  .dropdown-component__content {
    left: 0;
  }
  .dropdown-component__incoming {
    right: 0;
    @media screen and (max-width: 1623.98px) {
      right: none;
      left: 0;
    }
    @media screen and (max-width: 928.98px) {
      right: 0;
      left: initial;
    }
  }
  .dropdown-component__outgoing {
    right: 0;
    @media screen and (max-width: 1170.98px) {
      right: none;
      left: 0;
    }
    @media screen and (max-width: 926.98px) {
      right: 0;
      left: initial;
    }
  }
}

.dropdown__item {
  color: $color1;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 0 5px 0px;
  position: relative;
  margin-bottom: 10px;
  text-align: left;
  cursor: pointer;
  .icomoon_ic_checkbox {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 10px;
  }
  .dropdown__item__inner {
    &.disabled {
      color: grey;
      .icomoon_ic_checkbox {
        color: grey;
      }
    }
  }
}

.dropdown-component__button {
  height: 32px;
  border: 1px solid #e0e5ee;
  background-color: #fff;
  padding: 0;
  text-align: center;
  width: 46px;
  transition: border-color 0.2s;
  display: flex;
  .icomoon_ic_arrow_drop_down {
    font-size: 4px;
    color: $color2;
    margin-left: 5px;
  }
  .icomoon {
    display: inline-block;
    vertical-align: middle;
  }
  .icomoon_ic_columns {
    font-size: 14px;
    color: $color3;
    transition: color 0.2s;
  }
  &:hover {
    border-color: #0070d2;
    .icomoon_ic_columns {
      color: $colorPrimary;
    }
  }
}

//switch
.switch {
  height: 21px;
  width: 40px;
  position: relative;
  cursor: pointer;
}

.switch__inner {
  background: $colorPrimary;
  border-radius: 12px;
  width: 100%;
  height: 100%;
}

.switch__button {
  height: 17px;
  width: 17px;
  background-color: #ffffff;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 50%;
  transform-origin: center center;
}

.switch.off {
  .switch__inner {
    background: $color3;
  }
}

.children {
  .switch__inner {
    background: $colorSecondary;
  }
}

//end switch
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login__inner {
  width: 320px;
  padding: 20px 30px 35px;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  img {
    width: 242px;
    display: block;
    margin: 0 auto 30px;
  }
}

.login {
  .form-group {
    margin-bottom: 20px;
  }
}

.form-group {
  width: 100%;
  position: relative;
}

.hidden-checkbox {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
}

.custom-checkbox {
  font-size: 12px;
  line-height: 15px;
  vertical-align: middle;
  color: $color2;
  &.disabled {
    color: #ccc;
    cursor: not-allowed;
    .fake-checkbox {
      background-color: #d8dde6;
    }
  }
}

.fake-checkbox {
  display: inline-block;
  height: 16px;
  width: 16px;
  border: 1px solid #d8dde6;
  border-radius: 2px;
  background-color: #ffffff;
  text-align: center;
  vertical-align: middle;
  margin-right: 5px;
  float: left;
  i {
    line-height: 16px;
    font-size: 7px;
    display: block;
  }
}

.login__form__submit {
  margin-top: 40px;
  .btn {
    width: 100%;
  }
}

.login {
  .form-control {
    padding-right: 25px;
  }
  .form-group {
    position: relative;
    i {
      position: absolute;
      right: 9px;
      top: 28px;
      font-size: 16px;
      color: #a8b7c7;
    }
  }
  .form-control:focus + i {
    color: $colorPrimary;
  }
  .remember-me {
    float: left;
  }
  .restore-password {
    float: right;
    font-size: 12px;
    line-height: 15px;
    vertical-align: middle;
    color: $color2;
    text-decoration: none;
    cursor: pointer;
  }
}

.error-message {
  margin-top: 20px;
  color: $color1;
  border: 1px solid #f00;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
}

.section__top__expand {
  position: absolute;
  right: 64px;
  top: 20px;
  font-size: 20px;
  color: $color3;
  cursor: pointer;
  transition: color 0.2s;
  border: 1px solid #d8dde6;
  box-sizing: border-box;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: $colorPrimary;
  }
}

.section__left,
.section__right {
  transition: all 0.2s;
}

.section__top__collapse {
  position: absolute;
  right: 0;
  top: 20px;
  height: 145px;
  width: 37px;
  border-radius: 5px 0 0 5px;
  background-color: $colorPrimary;
  cursor: pointer;
  display: none;
  z-index: 100;
}

.collapse-text {
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: center center;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
  text-align: center;
}

.section__left {
  .collapse-text {
    top: 60px;
    left: -40px;
  }
}

.section__right {
  .collapse-text {
    top: 61px;
    left: -32px;
  }
}

.show-ssp {
  .section__top__collapse {
    display: block;
  }
  .section__top__expand {
    display: none;
  }
  .columns-options-dropdown {
    right: 60px;
  }
  ._td:first-child {
    width: 100%;
    border: none;
  }
  ._td:last-child {
    width: 0;
    overflow: hidden;
    border: none;
  }
  .section__top__download {
    right: 114px;
  }
  .columns-options-dropdown-active {
    &.second {
      @include mediaDesktop($W1100) {
        margin-left: 10px;
        width: auto;
        margin-top: 0;
      }
    }
  }
}

.show-dsp {
  .section__top__collapse {
    display: block;
  }
  .section__top__expand {
    display: none;
  }
  .columns-options-dropdown {
    right: 60px;
  }

  ._td:first-child {
    width: 0;
    overflow: hidden;
    border: none;
  }
  ._td:last-child {
    width: 100%;
    border: none;
  }
  .section__top__download {
    right: 114px;
  }
  .columns-options-dropdown-active {
    &.second {
      @include mediaDesktop($W1100) {
        margin-left: 10px;
        width: auto;
        margin-top: 0;
      }
    }
  }
}

.section {
  min-width: 512px;
}

.table {
  height: 100%;
}

.section__table {
  padding: 0 5px;
  position: relative;
}

.section__table__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
}

.table__head {
  width: 100%;
  border-bottom: 1px solid #dde1e9;
  height: 95px;
  .th {
    white-space: nowrap;
    text-transform: uppercase;
    color: $color2;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    float: left;
    position: relative;
  }
}

.table__body {
  overflow: auto;
}

.th_defaultBidfloor,
.td_defaultBidfloor {
  white-space: normal !important;
}

.th_defaultTmax,
.td_defaultTmax,
.th_cookieSync,
.td_cookieSync {
  white-space: normal !important;
}

.th_toggle,
.td_toggle {
  .cell-inner {
    left: 50%;
    top: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
  i {
    display: block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: $colorPrimary;
  }
}

.vertical {
  font-size: 12px;
  line-height: 14px;
  text-transform: unset;
  text-align: left;
  padding-left: 15px;
}

.table__head .th_priceFromNurl {
  white-space: normal !important;
}

.table__head .th_useGeoedge {
  @extend .vertical;
  .cell-inner {
    position: absolute;
    bottom: 3px;
    left: 27px;
    right: auto;
    transform: rotate(-90deg);
    transform-origin: left center;
  }
}

.table__head .th_useTMT {
  @extend .vertical;
  .cell-inner {
    position: absolute;
    bottom: 3px;
    left: 27px;
    right: auto;
    transform: rotate(-90deg);
    transform-origin: left center;
  }
}

.table__head {
  .th_banner,
  .th_native,
  .th_video,
  .th_desktop,
  .th_inApp,
  .th_intstlOnly,
  .th_mobileWeb,
  .th_pop {
    @extend .vertical;
    .cell-inner {
      position: absolute;
      bottom: 3px;
      left: 27px;
      right: auto;
      transform: rotate(-90deg);
      transform-origin: left center;
    }
  }
}

.children {
  transition: all 0.3s;
}

.tr + .children {
  overflow: hidden;
  border-bottom: 1px solid #dde1e9;
}

.show-o {
  overflow: visible !important;
}

.tr {
  position: relative;
}

.tr.opened {
  background: #e9f2fa;
}

.child-options {
  background: #e9f2fa;
  padding: 0 10px 10px;
  overflow: hidden;
}

.child-options__inner {
  background: #fff;
  padding: 25px;
  &.stat {
    min-height: 300px;
  }
}

.td_toggle {
  cursor: pointer;
  i {
    transform-origin: center center;
  }
  &.opened {
    i {
      transform: rotate(90deg);
    }
  }
}

.platform-form {
  width: 60%;
  min-width: 600px;
  max-width: 940px;
  padding: 20px;
  .part {
    float: left;
    box-sizing: border-box;
    &.left {
      width: 45%;
      padding-right: 20px;
    }
    &.right {
      width: 55%;
      padding-left: 20px;
      border-left: 1px solid #e0e0e0;
    }
    .alt {
      width: 50%;
    }
    &.alt.right {
      padding-left: 5px;
      border-left: 0;
    }
    &.alt.left {
      padding-right: 5px;
    }
  }
  .form-part {
    padding-top: 7px;
  }
}

.control-label {
  padding-bottom: 5px;
  font-size: 13px;
  line-height: 16px;
  color: $color2;
  display: inline-block;
}

textarea[disabled] {
  background: $color4;
  cursor: not-allowed;
}
.custom-select {
  position: relative;
  font-size: 13px;
  background: #fff;
}

[disabled] .custom-select {
  background-color: $color4;
  cursor: not-allowed;
}

app-select.ng-invalid.ng-touched .custom-select {
  border: 2px solid #c23934;
  border-radius: 4px;
  .custom-select__value {
    border: none;
  }
}

.custom-select__value {
  height: 32px;
  position: relative;
  padding: 0px 5px 1px 15px;
  border: 1px solid #a8b7c7;
  border-radius: 4px;
  outline: none;
  color: $color1;
  z-index: 99;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  span {
    width: auto;
    overflow: hidden;
  }
  .select-icon {
    margin-top: -5px;
  }
}

.custom-reports__value {
  @extend .custom-select__value;
  padding-top: 6.5px;
  padding-left: 6.5px !important;
}

.allowed-filter.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #4bca81;
}

.blocked-filter.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ca5c31;
}

.allowed-filter {
  .custom-select__value {
    border: 1px solid #4bca81;
  }
}
.blocked-filter {
  .custom-select__value {
    border: 1px solid #ca5c31;
  }
}
.default-filter {
  .custom-select__value {
    border: 1px solid #a8b7c7;
  }
}
.focus {
  border: none !important;
  .custom-select__value {
    border: 1px solid #0076de !important;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .search-container {
    background: #fff;
  }
}

.custom-select__search {
  padding: 1px 5px 1px 15px;
  border: none;
  outline: none;
  height: 32px;
  display: block;
  width: 100%;
  background: transparent;
}

.custom-select__dropdown__search {
  padding: 0 10px 10px;
  position: relative;
  .custom-select__search {
    border: 1px solid #a8b7c7;
    border-radius: 4px;
    height: 26px;
    &:focus {
      border: 1px solid #0076de;
    }
  }
}

.search-container {
  height: 34px;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  background: transparent;
  z-index: 1000;
  border-radius: 4px;
}

.custom-select {
  .calendar-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -10px;
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(../assets/img/calendar.svg);
    background-size: cover;
    cursor: pointer;
  }
}

.custom-select__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: -1px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
  display: none;
  border: 1px solid #0076de;
  border-top: 0;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  z-index: 1111;
  .custom-select__items {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 150px;
    overflow: auto;
  }

  .custom-select__companies {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 125px;
    overflow: auto;
  }
  .item {
    padding: 5px 10px 5px 15px;
    transition: background 0.2s;
    cursor: pointer;
    &:hover,
    &.selected {
      background: #ecf4fa;
    }
    &.disabled {
      background: #e9f2fa;
      cursor: not-allowed;
      text-decoration: line-through;
      color: #ccc;
    }
    &.hasSubitems {
      font-weight: 800;
    }

    &.custom {
      text-align: center;
      span {
        font-weight: bold;
      }
    }
  }

  .subitem {
    padding: 5px 10px 5px 25px;
    font-weight: normal;
    cursor: pointer;
    &:hover,
    &.selected {
      background: #ecf4fa;
    }
  }
}

.focus {
  .custom-select__dropdown {
    display: block;
  }
  .search-container {
    display: block;
  }
}

.shadow-overlay {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  height: 3px;
  left: 0;
  display: none;
  top: 0;
  z-index: 100000;
}

.focus {
  .shadow-overlay {
    display: block;
  }
}

.custom-select__value {
  padding-right: 10px;
  .pill {
    display: inline-block;
    padding: 5px;
    color: $colorPrimary;
    font-size: 13px;
    line-height: 1;
    border: 1px solid #d8dde6;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    position: relative;
    z-index: 99;
    margin-bottom: 3px;
    i {
      font-size: 20px;
      margin-left: 5px;
      height: 10px;
      vertical-align: middle;
      line-height: 0.3;
      color: $color2;
      display: inline-block;
    }
  }
}
.endpoint-edit-form {
  .custom-select__value {
    .pill {
      word-break: break-all;
    }
  }
}

.multiple-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-height: 300px;
}

.multiple {
  .custom-select__value {
    padding-left: 5px;
    padding-top: 2px;
  }
  .custom-select__dropdown {
    top: 100%;
    padding-top: 10px;
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .shadow-overlay {
    height: 8px;
    margin-top: -13px;
    left: 1px;
    right: 1px;
    width: auto;
  }
}

.freaky {
  padding-left: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.platform-form {
  .form-group {
    margin-bottom: 10px;
  }
  .form-group_chb {
    margin-bottom: 15px;
  }
  .form-group_fl {
    padding-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    app-checkbox + app-checkbox {
      margin-left: 25px;
    }
  }
  .form-group_fl1 {
    app-checkbox:last-child {
      margin-left: 0;
      margin-top: 25px;
    }
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-27 {
    margin-bottom: 27px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
}

.lists {
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  color: #16325c;
  .bundle {
    cursor: default;
    margin-bottom: 15px;
  }
  .title {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #54698d;
  }
  .wrap {
    padding-top: 6px;
    padding-left: 0;
    padding-right: 0;
    .action {
      border: 1px solid #a8b7c7;
      text-align: center;
      color: #16325c;
      font-weight: normal;
      height: 32px;
      &.ads {
        color: #0070d2;
      }
    }
    .quantity {
      border: 1px solid #d9d9d9;
      text-align: center;
      font-size: 13px;
      line-height: 18px;
      padding-top: 7px;
      padding-bottom: 7px;
      cursor: pointer;
      color: #16325c;
      font-weight: normal;
      height: 32px;

      &.blocked {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        padding-top: 7px;
        padding-bottom: 7px;
        border: 1px solid #eb5757;
        color: #eb5757;
      }
      &.allowed {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        padding-top: 7px;
        padding-bottom: 7px;
        border: 1px solid #4bca81;
        color: #4bca81;
      }
    }
    &.blocked {
      .action {
        border: 1px solid #eb5757;
      }
      .quantity {
        border: 1px solid #eb5757;
        color: #eb5757;
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
    &.allowed {
      .action {
        border: 1px solid #4bca81;
      }
      .quantity {
        border: 1px solid #4bca81;
        color: #4bca81;
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}

.blocked-action {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px solid #eb5757;
  color: #16325c;
  height: 32px;
}
.allowed-action {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px solid #4bca81;
  color: #16325c;
  height: 32px;
}

.platform-form__bottom__buttons {
  .btn {
    margin-right: 10px;
  }
}

.form-bottom__buttons {
  .btn {
    margin-right: 10px;
    &.right {
      float: right;
      margin-right: 0;
    }
  }
}

b {
  font-weight: bold;
}

.platform-form__top {
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: $color1;
  font-size: 20px;
  position: relative;
  padding: 0 25px;
}

.mat-dialog-container {
  padding: 0 !important;
  .platform-form {
    width: 100%;
    padding: 0 !important;
  }
}

.platform-form__middle {
  padding: 25px;
}

.platform-form__bottom {
  background: #f7f9fb;
  padding: 20px 25px;
}

.stat-link {
  color: #0070d2;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
}

.icons-wrap {
  position: absolute;
  top: 50%;
  opacity: 1;
  transition: opacity 0.2s;
  right: 10px;
  cursor: pointer;
  margin-top: -12px;
  .sandwich {
  }
  .drop {
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid #d8dde6;
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(84, 105, 141, 0.5);
    border-radius: 4px;
    background: #fff;
    position: absolute;
    left: -85px;
    top: 25px;
    width: 110px;
    display: none;
    z-index: 9999;

    .item {
      font-size: 12px;
      line-height: 19px;
      color: #16325c;
      display: block;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 8px;
      padding-right: 5px;
      cursor: pointer;
      &:hover {
        background: #f5f5f6;
      }
    }
  }
  &:hover {
    .sandwich {
      svg {
        path {
          fill: #0070d2;
        }
      }
    }
    .drop {
      display: block;
    }
  }
}
.tr .td.td_full_name {
  overflow: unset;
}
.tr + .children.ng-trigger-slideInOut {
  transition: all 400ms;
  overflow: unset;
}

.opened .icons-wrap {
  background: #e9f2fa;
}

.create-user__top {
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: $color1;
  font-size: 20px;
  padding: 0px 25px;
}

.create-user__middle {
  padding: 15px 15px 35px 15px;
  .form-group {
    margin-bottom: 10px;
  }
}

.create-user__bottom {
  background: #f7f9fb;
  padding: 33px 25px;
  .btn {
    margin-right: 10px;
  }
}

.user-button {
  position: absolute;
  height: 50px;
  right: 0;
  width: 46px;
  text-align: right;
  padding: 13px 10px;
  cursor: pointer;
  i {
    color: $colorPrimary;
    font-size: 24px;
  }
}
.documentation-button {
  width: 27px;
  height: 27px;
  position: absolute;
  top: 11px;
  right: 46px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  img {
    vertical-align: middle;
  }
}

.user-menu {
  position: absolute;
  top: 100%;
  padding: 10px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  width: 210px;
  right: 10px;
  font-size: 14px;
  line-height: 24px;
  display: none;
  z-index: 10000;
  text-align: left;
  a {
    display: block;
    text-decoration: none;
    color: $color1;
    &:hover {
      font-weight: 600;
    }
  }
}

.user-button:hover {
  .user-menu {
    display: block;
  }
}

.user-management__top {
  padding: 20px 20px 30px;
  h2 {
    margin-bottom: 15px;
  }
}

.user-management__table {
  overflow: auto;
  background: #fff;
  position: relative;
  .bg {
    position: fixed;
    top: 162px;
    left: 0;
    right: 0;
    height: 56px;
    width: 100%;
    background: #fff;
    display: block;
    z-index: 10;
    border-bottom: 1px solid #dde1e9;
  }
  .user__name {
    width: 1px;
    white-space: nowrap;
  }
  .user__email {
    width: 1px;
  }
  .user__permissions {
    width: 100px;
  }
  .user__company {
    width: 100px;
  }
  td.user__name {
    color: $colorPrimary;
    font-weight: 500;
  }
  tr {
    position: relative;
  }
}

.delete-user {
  position: absolute;
  right: 15px;
  font-size: 32px;
  transition: opacity 0.2s;
  cursor: pointer;
  border: 3px solid #c3bdbd;
  padding: 3px;
  border-radius: 4px;
  background-color: #d44e4e;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  height: 30px;
}

.datepicker-wrap {
  .mat-form-field {
    font-size: 14px;
  }
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    font-size: 24px;
  }
}

.switch.disabled {
  cursor: not-allowed;
}

.platform-form__lists {
  width: 100%;
  min-width: 100%;

  .platform-form__middle {
    display: flex;
    justify-content: center;
    flex-wrap: no-wrap;
    padding-top: 0;
  }
  .lists-label {
    padding-left: 25px;
    text-transform: capitalize;
    padding-top: 23px;
    padding-bottom: 6px;
    font-size: 13px;
    line-height: 15px;
    color: $color2;
    display: block;
  }
  input[type='file'] {
    opacity: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
  }
}

.platform-form__middle__buttons {
  max-width: 120px;
  button {
    margin-bottom: 10px;
  }
}

.page-404 {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 400px;
}

.page-404__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    color: $colorPrimary;
    font-size: 72px;
    line-height: 85px;
    text-align: center;
    margin-bottom: 65px;
    span {
      display: block;
      margin-top: 7px;
      font-size: 36px;
      line-height: 42px;
    }
  }
}

.expanding-table {
  width: 100%;
  text-align: left;
  overflow-x: hidden !important;
  &.failure-desc {
    width: 10%;
  }
}

td.bt-company {
  cursor: pointer;
  color: $colorPrimary;
  span {
    font-weight: 500;
  }
}

.bt-toggle {
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  i {
    color: $colorPrimary;
    transform: rotate(0);
    transform-origin: center center;
    transition: transform 0.2s;
    display: block;
  }
  &.opened i {
    transform: rotate(90deg);
  }
}

.td-toggle {
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  img {
    color: $colorPrimary;
    transform: rotate(0);
    transform-origin: center center;
    transition: transform 0.2s;
    display: block;
  }
  &.opened img {
    transform: rotate(90deg);
  }
}

.billing-top {
  padding: 20px;
}

.billing-top__filter form {
  display: flex;
  padding: 0 20px;
  flex-direction: row;
  align-items: flex-end;
  label {
    color: $color2;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 16px;
    display: block;
  }
  .btn {
    margin-right: 20px;
    text-transform: uppercase;
    &.end-switch {
      text-transform: none;
    }
  }
  .form-group {
    position: relative;
  }
  my-date-range-picker {
    position: absolute;
    top: 100%;
  }
  .btn.to-right {
    margin-right: 0;
    margin-left: auto;
  }
}

.filter-type {
  width: 75px;
  margin-right: 20px;
}

.filter-company {
  width: 220px;
  margin-right: 20px;
}

.filter-period {
  width: 120px;
  margin-right: 20px;
}

.billing-total {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #dde1e9;
  height: 45px;
}

.company-top {
  padding: 20px 20px 5px 20px;
  .btn {
    margin: 20px 0 40px;
  }
}

.company-table {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 194px;
  overflow: auto;
  tr {
    position: relative;
  }
  [class*='c_'] {
    text-align: left;
    padding-right: 25px;
    white-space: nowrap;
  }
  .c_name {
    width: 190px;
    padding-left: 35px;
    white-space: normal;
    word-break: break-all;
  }
  .c_domain {
    width: 190px;
    padding-left: 35px;
    white-space: normal;
    word-break: break-all;
  }
  .c_date {
    width: 113px;
  }

  .c_contact {
    width: 100px;
  }

  .c_email {
    width: 165px;
  }
  .c_terms {
    width: 80px;
  }
  .c_start {
    width: 100px;
    padding-right: 25px;
  }
  .c_invoice {
    width: 100px;
    padding-right: 25px;
  }
  table {
    tbody {
      tr {
        td.c_payment {
          position: relative;
        }
      }
    }
  }
}

.filter-table {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 194px;
  overflow: auto;
  [class*='c_'] {
    text-align: left;
    padding-right: 25px;
    white-space: nowrap;
  }
  .c_name {
    width: 100px;
    padding-left: 35px;
    white-space: normal;
    word-break: break-all;
  }
  .c_domain {
    width: 90px;
    padding-left: 35px;
    white-space: normal;
    word-break: break-all;
  }
  .c_date {
    width: 80px;
  }

  .c_contact {
    width: 60px;
  }

  .c_email {
    width: 80px;
  }
  .c_terms {
    width: 80px;
  }
  .c_start {
    width: 100px;
    padding-right: 25px;
  }
  .c_invoice {
    width: 100px;
    padding-right: 25px;
  }
  table {
    tbody {
      tr {
        td.c_payment {
          position: relative;
        }
      }
    }
  }
}

.row {
  padding: 0 10px;
}
.col-2 {
  width: 50%;
  float: left;
  padding: 0 10px;
}

.col-4 {
  padding: 0 10px;
}

.company-settings {
  background: #fff;
  padding: 20px 0;
  .row {
    width: 585px;
  }
}

.company-new {
  background: #fff;
  padding: 20px 0;
  .row {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
}

.company-settings__cell {
  background: #e9f2fa;
}
.company-settings {
  .form-group {
    margin-bottom: 10px;
    .form-control {
      font-size: 13px;
      line-height: 1;
    }
  }
  .form-group_dp {
    margin-bottom: 6px;
  }
}
.company-settings__buttons {
  margin-top: 40px;
  .btn {
    margin-right: 10px;
  }
}
.c_name {
  span {
    font-weight: bold;
    color: $colorPrimary;
    cursor: pointer;
  }
}

.form-group_terms {
  .form-control {
    padding-left: 33px;
  }
  .net {
    position: absolute;
    bottom: 9px;
    left: 10px;
    font-size: 12px;
    margin-bottom: 0;
    color: $color1;
    line-height: 13px;
  }
}

.main-stat {
  text-align: left !important;
  padding-left: 15px;
}

.ng-invalid.ng-touched > .mydp {
  border: 2px solid #c23934;
}

.create-company__top {
  padding: 20px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: $color1;
  text-transform: capitalize;
}

.child-options {
  .stat-table {
    h2 {
      text-align: left !important;
    }
  }
}

.test-link-button {
  margin-top: 22px;
  width: 100%;
  margin-bottom: 10px;
}

.test-link-response {
  padding: 20px;
  strong {
    font-weight: bold;
  }
  li {
    margin-bottom: 20px;
  }
  .company-settings__buttons {
    padding-left: 0;
  }
}

.sorting {
  cursor: progress !important;
}

.sorting-indicator {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  position: relative;
  .sort-icon {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
  }
  .sort-icon-asc {
    background-image: url('../assets/img/sort-asc.svg');
    transform: translate(0, 25%);
  }
  .sort-icon-desc {
    background-image: url('../assets/img/sort-desc.svg');
    transform: translate(0, -25%);
  }
  .sort-icon-normal {
    background-image: url('../assets/img/sort.svg');
  }
}

.settings-submit {
  text-align: center;
  margin-top: 30px;
  .btn {
    width: 100%;
    max-width: 300px;
  }
}
.discrep {
  position: relative;
  left: 10px;
  bottom: 9px;
}
mat-dialog-container {
  overflow: hidden !important;
}
.text-center {
  text-align: center;
}
.reset-success {
  text-align: center;
  margin-bottom: 30px;
}
/*time saver*/
.cdk-overlay-pane {
  height: auto !important;
}

.section__top .button_hide_show_campaigns {
  margin-left: 7.5px;
  @include mediaDesktop($W1590) {
    display: none;
  }
  @include mediaDesktop($W1375) {
    display: none;
  }
  @include mediaDesktop($W1100) {
    display: none;
  }
}
.columns-options-dropdown-active {
  &.second {
    @include mediaDesktop($W1100) {
      margin-left: 0;
      width: 118px;
      margin-top: 10px;
    }
  }
}

.partner-notify {
  width: 30% !important;
  height: 200px;
  top: 50px;
}

.email-error-settings {
  padding: 3px 5px;
  color: #c23934;
}

.columns-options-dropdown-active {
  display: inline-block;
  margin: 0 2.5px;
  width: 100px;
  &--state {
  }
  &--filter {
  }
}
.w-115 {
  width: 130px;
}
#note_about_blocker {
  display: none;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 320px;
  text-align: center;
  background-color: #eee;
  margin-top: 25px;
  padding: 45px 10px;
  border: 3px solid red;
  border-radius: 5px;
  line-height: 1.5;
}

.request-link {
  word-break: break-word;
  word-wrap: break-word;
}

.delete-endpoint {
  font-size: 36px;
  transition: opacity 0.2s;
  cursor: pointer;
  color: #c9cacd;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
}
.delete-endpoint:hover {
  color: $colorPrimary;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.request-support {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: $colorPrimary;
  margin-top: 10px;
  cursor: pointer;
  margin-left: 35px;
  margin-bottom: 25px;
}

.header-feature {
  position: absolute;
  right: 104px;
  top: 8px;
}

.pull-right {
  float: right;
}

/**********New (ssp/dsp) Connection style adds***********/

.form-title {
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 19px;
  color: #16325c;
  &--checkbox {
    padding-bottom: 10px;
  }

  &--traffic {
    padding: 10px 0;
  }

  &--size {
    padding-bottom: 10px;
  }

  &.mt-20 {
    margin-top: 20px;
  }
  .icon {
    margin-left: 6px;
    display: inline-block;
  }
}
.form-group {
  label {
    .icon {
      margin-left: 2.5px;
      display: inline-block;
    }
  }
  &.form-group_check {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;
    app-checkbox + app-checkbox {
      margin-left: 25px;
    }
    .b-row {
      width: 100%;
    }
    .prebid-wrapper {
      width: 100%;
      margin-top: 20px;
      .check-tooltip {
        flex-basis: 100%;
        align-self: flex-start;
        display: flex;
        app-checkbox {
          display: flex;
          width: auto;
        }
        & + .check-tooltip {
          margin-left: 0 !important;
        }
        .icon {
          margin-left: 6px;
          display: inline-block;
          vertical-align: middle;
          margin-top: 0;
          position: relative;
          svg {
            path {
              transition: all 400ms;
            }
          }
          &:hover {
            cursor: pointer;
            svg {
              path {
                fill: #16325c;
              }
            }
          }
        }
      }
      .select-tooltip {
        flex-basis: 100%;
        align-self: flex-start;
        display: flex;
        app-select {
          width: 260px;
          display: block;
        }
        & + .check-tooltip {
          margin-left: 0 !important;
        }
        .icon {
          margin-left: 6px;
          display: inline-block;
          vertical-align: middle;
          margin-top: 0;
          position: relative;
          svg {
            margin-top: 9px;
            path {
              transition: all 400ms;
            }
          }
          &:hover {
            cursor: pointer;
            svg {
              path {
                fill: #16325c;
              }
            }
          }
        }
      }
    }
  }
  &.form-group_filcheck {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;
    app-checkbox + app-checkbox {
      margin-left: 25px;
    }
    .b-row {
      width: 100%;
    }
    .prebid-wrapper {
      width: 100%;
      margin-top: 20px;
      .check-tooltip {
        flex-basis: 100%;
        align-self: flex-start;
        display: flex;
        app-checkbox {
          display: flex;
          width: auto;
        }
        & + .check-tooltip {
          margin-left: 0 !important;
        }
        .icon {
          margin-left: 6px;
          display: inline-block;
          vertical-align: middle;
          margin-top: 0;
          position: relative;
          svg {
            path {
              transition: all 400ms;
            }
          }
          &:hover {
            cursor: pointer;
            svg {
              path {
                fill: #16325c;
              }
            }
          }
        }
      }
      .select-tooltip {
        flex-basis: 100%;
        align-self: flex-start;
        display: flex;
        app-select {
          width: 260px;
          display: block;
        }
        & + .check-tooltip {
          margin-left: 0 !important;
        }
        .icon {
          margin-left: 6px;
          display: inline-block;
          vertical-align: middle;
          margin-top: 0;
          position: relative;
          svg {
            margin-top: 9px;
            path {
              transition: all 400ms;
            }
          }
          &:hover {
            cursor: pointer;
            svg {
              path {
                fill: #16325c;
              }
            }
          }
        }
      }
    }
  }
  &.filter-group {
    margin-bottom: 0;
  }
  &.no-b-bottom {
    border-bottom: 0;
  }
  &.no-m-b {
    margin-bottom: 0 !important;
  }
}
.prebid-wrapper {
  width: 100%;
  margin-top: 20px;
  .check-tooltip {
    flex-basis: 100%;
    align-self: flex-start;
    display: flex;
    app-checkbox {
      display: flex;
    }
    & + .check-tooltip {
      margin-left: 0 !important;
    }
    .icon {
      margin-left: 6px;
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      position: relative;
      svg {
        path {
          transition: all 400ms;
        }
      }
      &:hover {
        cursor: pointer;
        svg {
          path {
            fill: #16325c;
          }
        }
      }
    }
  }
  .select-tooltip {
    flex-basis: 100%;
    align-self: flex-start;
    display: flex;
    app-select {
      width: calc(100% - 20px);
      display: block;
    }
    & + .check-tooltip {
      margin-left: 0 !important;
    }
    .icon {
      margin-left: 6px;
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      position: relative;
      svg {
        margin-top: 9px;
        path {
          transition: all 400ms;
        }
      }
      &:hover {
        cursor: pointer;
        svg {
          path {
            fill: #16325c;
          }
        }
      }
    }
  }
}
.new-tooltip {
  background: #16325c;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  padding: 10px 5px;
  box-shadow: 0px 2px 3px #a8b7c7;
  left: 0;
  max-width: 260px;
  margin-left: 0;
}

.item-tooltip {
  background: transparent;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  padding: 10px 5px;
  left: 0;
  max-width: 1000px !important;
  word-break: break-all;
}

.dsp-limit {
  position: relative;

  .form-control {
    padding-left: 10px;
  }
  &:before {
    content: '$';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    line-height: 18px;
    color: #333333;
  }
}
.perc-place {
  position: relative;

  .form-control {
    padding-right: 10px;
  }
  &:before {
    content: '%';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    line-height: 18px;
    color: #a8b7c7;
  }
}
.ssp-limit {
  position: relative;

  .form-control {
    padding-left: 10px;
  }
  &:before {
    content: '$';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    line-height: 18px;
    color: #333333;
  }
}
.column-check {
  flex-direction: column;
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
  app-checkbox {
    flex-basis: 100%;
    margin-bottom: 10px;
    align-self: flex-start;
    & + app-checkbox {
      margin-left: 0 !important;
    }
  }
}
.checkbox-with-drop {
  &.alter {
    app-checkbox {
      flex-basis: 58px;
    }
  }
}
.hub-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  .hub-part {
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    app-checkbox + app-checkbox {
      margin-left: 0;
    }
    app-checkbox {
      margin-bottom: 10px;
      display: block;
    }
    &.hub-left {
      flex-basis: 40%;
    }
    &.hub-right {
      flex-basis: 60%;
    }
  }
}
.b-row {
  padding-left: 0;
  padding-right: 0;
}
.dsp-api-link-wrap {
  .test-link-button {
    margin-top: 22px;
    min-width: 100%;
  }
  small {
    font-size: 11px;
    color: #54698d;
  }
}
.dsp-generate {
  min-width: 190px;
}

.dsp-edit-child {
  padding: 0 20px 20px 20px;
}
.endpoint-edit-form {
  padding: 0;
  .platform-form__top {
    margin-bottom: 20px;
  }
  .part {
    &.left {
      width: 50%;
    }
    &.right {
      width: 50%;
    }
  }
  .endpoint-link {
    .button {
      margin-top: 20px;
      min-width: 100%;
    }
  }
  .adavanced-settings-wrap {
    padding-top: 20px;
    border-width: 1px 0;
  }
  .req-res-wrap {
    padding-top: 20px;
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
hr {
  border: 0.5px solid #e0e0e0;
}
.platform-form {
  .form-group {
    margin-bottom: 20px;
  }
}
.dsp-stat-wrap {
  margin-top: 20px;
  small {
    font-size: 11px;
    color: #54698d;
  }
  .btn {
    margin-top: 20px;
    min-width: auto;
    width: 100%;
  }
}

.list-form-group {
  padding-left: 25px;
  padding-top: 20px;
  margin-bottom: 0 !important;
}
.table__body {
  position: relative;
  padding-top: 95px;
  overflow: auto;
  overflow-y: hidden;
  .table-body-inner {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .table__head {
    position: absolute;
    top: 0;
    left: 0;
    height: 95px;
  }
}

/**********New (ssp/dsp) Connection style adds***********/

.form-title {
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 19px;
  color: #16325c;
  &.mt-20 {
    margin-top: 20px;
  }
  &--checkbox {
    padding-bottom: 10px;
  }
  &--traffic {
    padding: 10px 0;
  }
  &--size {
    padding-bottom: 10px;
  }

  &--additional,
  &--filters,
  &--scanners {
    padding-bottom: 10px;
  }

  .icon {
    margin-left: 6px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
    svg {
      path {
        transition: all 400ms;
      }
    }
    &:hover {
      cursor: pointer;
      svg {
        path {
          fill: #16325c;
        }
      }
    }
  }
}
.form-group {
  label {
    .icon {
      margin-left: 6px;
      display: inline-block;
      svg {
        path {
          transition: all 400ms;
        }
      }
      &:hover {
        cursor: pointer;
        svg {
          path {
            fill: #16325c;
          }
        }
      }
    }
  }
  &.form-group_check {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;
    app-checkbox + app-checkbox {
      margin-left: 25px;
    }
    .b-row {
      width: 100%;
      app-checkbox {
        display: inline-block;
      }
      .icon {
        margin-left: 6px;
        display: inline-block;
        vertical-align: top;
        margin-top: 0;
        svg {
          path {
            transition: all 400ms;
          }
        }
        &:hover {
          cursor: pointer;
          svg {
            path {
              fill: #16325c;
            }
          }
        }
      }
    }
  }
  &.filter-group {
    margin-bottom: 0;
  }
  &.no-b-bottom {
    border-bottom: 0;
  }
  &.no-m-b {
    margin-bottom: 0 !important;
  }
}

.new-tooltip {
  background: #16325c;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  padding: 10px 20px;
  box-shadow: 0px 2px 3px #a8b7c7;
  left: 0;
  max-width: 260px;
  margin-left: 0;
  overflow: unset !important;
  white-space: pre-line;
  &:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #16325c;
    position: absolute;
    left: 50%;
    margin-left: -6px;
    transform: rotate(45deg);
    bottom: -6px;
    z-index: 111111;
    display: none;
  }
}

.dsp-limit {
  position: relative;

  .form-control {
    padding-left: 10px;
  }
  &:before {
    content: '$';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    line-height: 18px;
    color: #333333;
  }
}
.ssp-limit {
  position: relative;

  .form-control {
    padding-left: 10px;
  }
  &:before {
    content: '$';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
    line-height: 18px;
    color: #333333;
  }
}
.column-check {
  flex-direction: column;
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
  app-checkbox {
    flex-basis: 100%;
    margin-bottom: 10px;
    align-self: flex-start;
    & + app-checkbox {
      margin-left: 0 !important;
    }
  }
  .check-tooltip {
    flex-basis: 100%;
    align-self: flex-start;
    display: flex;
    align-items: center;
    app-checkbox {
      display: flex;
    }
    & + .check-tooltip {
      margin-left: 0 !important;
    }
    .icon {
      margin-left: 6px;
      display: inline-block;
      vertical-align: middle;
      margin-top: 5.5px;
      svg {
        path {
          transition: all 400ms;
        }
      }
      &:hover {
        cursor: pointer;
        svg {
          path {
            fill: #16325c;
          }
        }
      }
    }
  }
}
.hub-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  .hub-part {
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    app-checkbox + app-checkbox {
      margin-left: 0;
    }
    app-checkbox {
      margin-bottom: 10px;
      display: block;
    }
    &.hub-left {
      flex-basis: 40%;
    }
    &.hub-right {
      flex-basis: 60%;
    }
  }
}
.b-row {
  padding-left: 0;
  padding-right: 0;
}
.dsp-api-link-wrap {
  .test-link-button {
    margin-top: 22px;
    min-width: 100%;
  }
  small {
    font-size: 11px;
    color: #54698d;
  }
}
.dsp-generate {
  min-width: 190px;
}

.dsp-edit-child {
  padding: 0 20px 20px 20px;
}
.endpoint-edit-form {
  padding: 0;
  .platform-form__top {
    margin-bottom: 20px;
  }
  .part {
    &.left {
      width: 50%;
    }
    &.right {
      width: 50%;
    }
  }
  .endpoint-link {
    .btn {
      margin-top: 20px;
      min-width: 100%;
    }
  }
  .adavanced-settings-wrap {
    padding-top: 20px;
    border-width: 1px 0;
  }
  .req-res-wrap {
    padding-top: 20px;
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
.platform-form {
  .form-group {
    margin-bottom: 15px;
  }
}
.dsp-stat-wrap {
  margin-top: 20px;
  small {
    font-size: 11px;
    color: #54698d;
  }
  .btn {
    margin-top: 20px;
    min-width: auto;
    width: 100%;
  }
}

.list-form-group {
  padding-left: 25px;
  padding-top: 20px;
  margin-bottom: 0 !important;
}

.ssp-stat-wrap {
  padding-top: 20px;
  border-width: 1px 0;
  small {
    font-size: 11px;
    color: #54698d;
  }
  .btn {
    margin-top: 20px;
    min-width: auto;
    width: 100%;
  }
}

/*New Responsive Styles*/

.header__nav {
  @include mediaDesktop($W1170) {
    margin-left: 30px;
  }
  li {
    @include mediaDesktop($W1540) {
      margin-right: 15px;
    }
    @include mediaDesktop($W1400) {
      margin-right: 35px;
    }
    @include mediaDesktop($W1170) {
      margin-right: 15px;
    }
    a {
      @include mediaDesktop($W1400) {
        width: auto;
      }
    }
  }
}

.section__top__search {
  .form-group_inline {
    @include mediaDesktop($W1470) {
      max-width: 185px;
    }
    @include mediaDesktop($W1375) {
      max-width: 155px;
    }
    @include mediaDesktop($W1100) {
      max-width: 255px;
    }
  }
}

.section__top__download {
  position: absolute;
  right: 104px;
  top: 20px;
  font-size: 20px;
  color: #a8b7c7;
  cursor: pointer;
  transition: color 0.2s;
  border: 1px solid #d8dde6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.section__top__button__campaigns {
  @media screen and (min-width: 1591.98px) {
    display: none;
  }
  position: absolute;
  right: 154px;
  top: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.custom-modal {
  .mat-dialog-container {
    overflow: hidden !important;
  }
}

.mat-dialog-container {
  overflow: hidden !important;
}

.single-calendar.ng-touched.ng-invalid .form-control {
  border: 2px solid #c23934;
}

.pre-bid {
  display: inline-block !important;
  width: 50% !important;
}

.pre-bid-percent {
  margin-left: 10px;
  display: inline-block;
}

.pre-bid[disabled] {
  background: $color4;
  cursor: not-allowed;
}

.mt-20 {
  margin-top: 20px;
}

app-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 99vh;
}
.float-right {
  float: right !important;
}

.cdk-overlay-container {
  z-index: 9999;
}

.drop-table {
  top: 250px;
  .icon {
    margin-left: 6px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
    svg {
      path {
        transition: all 400ms;
      }
    }
    &:hover {
      cursor: pointer;
      svg {
        path {
          fill: #16325c;
        }
      }
    }
  }
}

.scanns {
  width: 100%;
  display: flex;
}

.notif-colored {
  color: #eb5757;
}

a.link-adapter {
  color: $colorPrimary;
  line-height: 18px;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  display: block;
  padding: 15px 0;

  &.disabled {
    cursor: not-allowed;
    color: #ccc;
  }

  &--custom {
    margin-top: -20px;
  }
}

.pt-0 {
  padding-top: 0;
}

.ssp-pr-2 {
  padding-right: 2px;
}
.scan-check {
  app-checkbox {
    margin-bottom: 10px;
    margin-right: 25px;
  }
  app-checkbox + app-checkbox {
    margin-left: 0 !important;
  }
}

:host ::ng-deep .ck-editor__editable {
  min-height: 700px !important;
}

.cdk-overlay-pane.full-scr-modal {
  max-width: 100% !important;
  width: 100%;
  height: 100% !important;
  .platform-form {
    width: 100%;
    max-width: 100%;
  }
}

.lists-modal {
  .platform-form__top {
    font-size: 20px;
    color: #16325c;
    span {
      color: #0070d2;
    }
    margin-bottom: 20px;
  }
  .lists-wrapper {
    .form-group {
      margin-bottom: 10px;
    }
    .btn {
      width: 100%;
      margin-bottom: 20px;
      &_delete {
        color: #eb5757;
      }
    }
    .form-title {
      font-size: 13px;
      line-height: 18px;
      color: #54698d;
      padding-bottom: 3px;
    }
  }
  .loader {
    margin-bottom: 20px;
    text-align: center;
    .image {
      img {
        width: 40px;
      }
    }
    .text {
      font-size: 13px;
      color: #0070d2;
    }
  }
}
.full-width {
  width: 100%;
}
.pl-select {
  padding: 1px 5px 1px 15px;
}
.margin-auto {
  margin: auto;
}
.pb-20 {
  padding-bottom: 20px;
}
.w-110 {
  width: 110px;
}

textarea {
  field-sizing: content;
  min-height: calc(24px * 4);
  min-height: 4lh;
  height: auto;
}
