// Define Sass variables
$gutter-x: 10px;
$gutter-y: 0px;

// Use variables in CSS rules
.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * #{$gutter-y});
  margin-right: calc(-0.5 * #{$gutter-x});
  margin-left: calc(-0.5 * #{$gutter-x});
}

.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(#{$gutter-x} * 0.5);
  padding-left: calc(#{$gutter-x} * 0.5);
  margin-top: #{$gutter-y};
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.w-100 {
  width: 100%;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 40px;
}

.mx-20 {
  margin-left: 5rem; /* 80px */
  margin-right: 5rem; /* 80px */
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-27 {
  margin-bottom: 27px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 80px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.container-fluid {
  width: 100%;
}
