.custom-radio {
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #54698d;
  &.red {
    color: #eb5757;
  }
  &.green {
    color: #27ae60;
  }
  &.disabled {
    cursor: not-allowed;
  }
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background: #ffffff;
  border: 1px solid #d8dde6;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0070d2;
}
.form-radio {
  .custom-radio {
    margin-top: 7px;
    display: inline-block;
    vertical-align: top;
    & + .custom-radio {
      margin-left: 20px;
    }
  }
  textarea {
    margin-top: 5px;
    padding-top: 7px;
    padding-left: 10px;
    font-size: 13px;
    line-height: 18px;
    height: 55px;
  }
  .btn {
    margin-top: 9px;
  }
}
